@font-face {
    font-family: "Graphik-Bold";
    src: url("./fonts/Graphik-Bold.otf");
}

@font-face {
    font-family: "Graphik-Medium";
    src: url("./fonts/Graphik-Medium.otf");
}

@font-face {
    font-family: "Graphik-Light";
    src: url("./fonts/Graphik-Light.otf");
}

@font-face {
    font-family: "Graphik-Regular";
    src: url("./fonts/Graphik-Regular.otf");
}